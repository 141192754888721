import React from 'react';
import styled from 'styled-components';
import AnimatedText from './AnimatedText';

const words = [
  ' web ✨ ',
  ' mobile ✨ ',
  ' good cause ✨  ',
  ' living ⚡️',
];

const StyledHero = styled.section`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const HiText = styled.h1`
  font-size: clamp(14px, 5vw, 16px);
  font-weight: 400;
  color: ${props => props.theme.highlight};
  margin-bottom: 20px;
`;

const Name = styled.h2`
  font-size: clamp(40px, 8vw, 80px);
  line-height: 1.1;
  margin-bottom: 10px;
  color: ${props => props.theme.mode === "light" ? props.theme.text : props.theme.lightestText};
`;

const Subtitle = styled.h3`
  font-size: clamp(40px, 8vw, 80px);
  line-height: 1.1;
  color: ${props => props.theme.mode === "light" ? props.theme.secondary : props.theme.text};
  margin-bottom: 20px;
`;

const Description = styled.p`
  max-width: 540px;
  font-size: clamp(16px, 5vw, 20px);
  color: ${props => props.theme.mode === "light" ? props.theme.text : props.theme.lightText};
`;

const Hero = () => {
  return (
    <StyledHero>
      <HiText>Hi, my name is</HiText>
      <Name>Puneeth Regonda.</Name>
      <Subtitle>
        I build things for   
        <AnimatedText words={words} delay={130} loop={true} />
      </Subtitle>
      <Description>
        I'm a software engineer specializing in building (and occasionally designing) exceptional digital experiences.
      </Description>
    </StyledHero>
  );
};

export default Hero;