// src/styles/colorValues.js
const colors = {
    dark: {
        bg: '#0a192f',
        lightBg: '#112240',
        lightestBg: '#233554',
        text: '#8892b0',
        lightText: '#a8b2d1',
        lightestText: '#ccd6f6',
        white: '#e6f1ff',
        highlight: '#64ffda',
    },
    light: {
        bg: '#F5F5F5',  
        lightBg: '#f8f9fa',  
        lightestBg: '#e9ecef',  
        text: '#333333',  
        lightText: '#5B6B7C',  
        lightestText: '#8493a5', 
        white: '#ffffff',  
        highlight: '#FF4500',
        secondary: '#1E90FF', 
        border: '#d0d7de',  
    }
//  F6F9FC bg 

    // light: {
    //     bg: '#F0F4FA',  
    //     lightBg: '#f8f9fa',  
    //     lightestBg: '#e9ecef',  
    //     text: '#2e3d49',  
    //     lightText: '#5b6b7c',  
    //     lightestText: '#8493a5', 
    //     white: '#ffffff',  
    //     highlight: '#7f675b',
    //     secondary: '#0056b3', 
    //     border: '#d0d7de',  
    // }
};
// F0F4FA white, ADB4BF grey , Blue 164BA1
export default colors;