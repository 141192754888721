// src/components/ThemeToggle.js
import React from 'react';
import styled from 'styled-components';

const ToggleButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: ${props => props.theme.highlight};
  color: ${props => props.theme.bg};
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1000;

  &:hover {
    transform: scale(1.1);
  }
`;

const ThemeToggle = ({ toggleTheme, isDarkTheme }) => {
  return (
    <ToggleButton onClick={toggleTheme}>
      {isDarkTheme ? '☀️' : '🌙'}
    </ToggleButton>
  );
};

export default ThemeToggle;
