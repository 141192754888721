// src/App.js
import React, { useState, useRef, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import GlobalStyle from './styles/GlobalStyle';
import { darkTheme, lightTheme } from './styles/themes';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Experience from './components/Experience';
import Projects from './components/Projects';
import Contact from './components/Contact';
import ThemeToggle from './components/ThemeToggle';
import Footer from './components/Footer';
import CodingProfiles from './components/CodingProfiles';

const StyledMain = styled.main`
  max-width: 90%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 1080px) {
    padding: 0 40px;
  }
  @media (max-width: 768px) {
    padding: 0 20px;
  }
  @media (max-width: 480px) {
    padding: 0 10px;
  }
`;

const Section = styled.div`
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
`;

function App() {
  const [isDarkTheme, setIsDarkTheme] = useState(true);
  const [visibleSections, setVisibleSections] = useState({});

  const toggleTheme = () => {
    setIsDarkTheme(!isDarkTheme);
  };

  const sectionRefs = useRef([]);
  const observer = useRef();

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Delay the visibility change by 0.5 seconds
          const id = entry.target.id;
          setTimeout(() => {
            setVisibleSections((prev) => ({
              ...prev,
              [id]: true,
            }));
          }, 100); // 500 milliseconds delay
        }
      });
    });

    sectionRefs.current.forEach((section) => {
      if (section) observer.current.observe(section);
    });

    return () => {
      observer.current.disconnect();
    };
  }, []);

  return (
    <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
      <GlobalStyle />
      <Header />
      <StyledMain>
        <Section ref={(el) => (sectionRefs.current[0] = el)} id="hero" className={visibleSections.hero ? 'visible' : ''}>
          <Hero />
        </Section>
        <Section ref={(el) => (sectionRefs.current[1] = el)} id="about" className={visibleSections.about ? 'visible' : ''}>
          <About />
        </Section>
        <Section ref={(el) => (sectionRefs.current[2] = el)} id="experience" className={visibleSections.experience ? 'visible' : ''}>
          <Experience />
        </Section>
        <Section ref={(el) => (sectionRefs.current[3] = el)} id="projects" className={visibleSections.projects ? 'visible' : ''}>
          <Projects />
        </Section>
        <Section ref={(el) => (sectionRefs.current[4] = el)} id="codingProfiles" className={visibleSections.codingProfiles ? 'visible' : ''}>
          <CodingProfiles />
        </Section>
        <Section ref={(el) => (sectionRefs.current[5] = el)} id="contact" className={visibleSections.contact ? 'visible' : ''}>
          <Contact />
        </Section>
      </StyledMain>

      <ThemeToggle toggleTheme={toggleTheme} isDarkTheme={isDarkTheme} />
      <Footer />
    </ThemeProvider>
  );
}

export default App;
