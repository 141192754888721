import React from 'react';
import styled from 'styled-components';

// Container for each project card
const CardContainer = styled.div`
  width: 300px;
  height: 300px;
  background-image: url(${props => props.bgImage});
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  position: relative;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.3);
  }
`;

// Overlay for card content (appears on hover)
const CardOverlay = styled.div`
  background: rgba(0, 0, 0, 0.7);
  color: white;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px;
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${CardContainer}:hover & {
    opacity: 1;
  }
`;

const Title = styled.h3`
  font-size: 1.5rem;
  text-align: center;
  margin: 0;
`;

const Links = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 10px;

  a {
    color: white;
    text-decoration: none;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 10px;
    border-radius: 5px;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
`;

// Card component
const ProjectCard = ({ title, bgImage, visitLink, sourceCodeLink, youtubeLink }) => {
  return (
    <CardContainer bgImage={bgImage}>
      <CardOverlay>
        <Title>{title}</Title>
        <Links>
          {visitLink && (
            <a href={visitLink} target="_blank" rel="noopener noreferrer">
              Visit
            </a>
          )}
          {sourceCodeLink && (
            <a href={sourceCodeLink} target="_blank" rel="noopener noreferrer">
              Source Code
            </a>
          )}
          {youtubeLink && (
            <a href={youtubeLink} target="_blank" rel="noopener noreferrer">
              YouTube
            </a>
          )}
        </Links>
      </CardOverlay>
    </CardContainer>
  );
};

export default ProjectCard;
