// src/components/Projects.js
import React from 'react';
import styled from 'styled-components';
import ProjectCard from './ProjectCard';
import projectsData from '../assets/data/projects.json'; // Import the JSON data

const ProjectsContainer = styled.section`
  padding: 0px; // Added padding around the section
`;

const ProjectsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); // Three columns
  gap: 20px;
  padding: 20px 0px; // Added padding around the section

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr); // Two columns on smaller screens
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr); // One column on very small screens
  }
`;

const Title = styled.h2`
  font-size: 2rem;
  margin: 0;
  padding: 0;
  color: ${props => props.theme.text};
  text-align: left;
`;

// Project list with card information
const Projects = () => {
  return (
    <ProjectsContainer>
      {/* Section Title */}
      <Title>Top Picks</Title>

      {/* Project Grid */}
      <ProjectsGrid>
        {projectsData.map((project, index) => {
          const bgImage = require(`../assets/images/${project.bgImage}`); // Dynamically require the image
          return (
            <ProjectCard
              key={index} // Use index as key; ideally, use a unique ID
              title={project.title}
              bgImage={bgImage} // Use the dynamically required image
              visitLink={project.visitLink}
              sourceCodeLink={project.sourceCodeLink}
              youtubeLink={project.youtubeLink}
            />
          );
        })}
      </ProjectsGrid>
    </ProjectsContainer>
  );
};

export default Projects;
