// src/components/Popup.js
import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaCheckCircle } from 'react-icons/fa'; // Import Font Awesome check icon

// Animation for the popup fade-in and fade-out
const fadeInOut = keyframes`
  0% { opacity: 0; transform: translateY(-20px); }
  20% { opacity: 1; transform: translateY(0); }
  80% { opacity: 1; transform: translateY(0); }
  100% { opacity: 0; transform: translateY(-20px); }
`;

// Popup container styles
const PopupContainer = styled.div`
  position: absolute; /* Absolute position relative to the button */
  top: -60px; /* Move the popup above the button */
  left: 50%;
  transform: translateX(-50%);
  background-color: #f0f4f8;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 1000;
  animation: ${fadeInOut} 3s ease-in-out;
`;

// Message styling
const PopupMessage = styled.div`
  font-size: 1rem;
  font-weight: 500;
  color: #333;
`;

const Popup = ({ message, onClose }) => {
  // Auto-hide popup after 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 3000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <PopupContainer>
      <FaCheckCircle size={24} color="green" />
      <PopupMessage>{message}</PopupMessage>
    </PopupContainer>
  );
};

export default Popup;
