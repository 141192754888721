// src/components/Contact.js
import React, { useState } from 'react';
import styled from 'styled-components';
import Popup from './Popup'; // Import the Popup component

const ContactContainer = styled.section`
  padding: 20px 0px;
  text-align: center;
`;

const IconList = styled.div`
  display: flex;
  justify-content: start;
  gap: 20px;
`;

const IconLink = styled.a`
  text-decoration: none;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.1);
  }
`;

const IconButtonWrapper = styled.div`
  position: relative; /* Make the wrapper of the button relative for proper popup positioning */
  display: inline-block;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.1);
  }
`;

const Icon = styled.img`
  width: 45px;
  height: 45px;
`;

function Contact() {
  const [showPopup, setShowPopup] = useState(false);

  const handleGmailClick = () => {
    navigator.clipboard.writeText('pr.regonda@gmail.com');
    setShowPopup(true); // Show the popup when clicked
  };

  return (
    <ContactContainer id="contact">
      <h2>Contact Me</h2>
      <IconList>
        <IconLink href="https://www.linkedin.com/in/puneethregonda" target="_blank">
          <Icon src="https://upload.wikimedia.org/wikipedia/commons/e/e9/Linkedin_icon.svg" alt="LinkedIn" />
        </IconLink>

        {/* Gmail Button with Popup */}
        <IconButtonWrapper>
          <IconButton onClick={handleGmailClick}>
            <Icon src="https://upload.wikimedia.org/wikipedia/commons/4/4e/Gmail_Icon.png" alt="Gmail" />
          </IconButton>

          {/* Show the popup above the button */}
          {showPopup && <Popup message="Gmail Copied!" onClose={() => setShowPopup(false)} />}
        </IconButtonWrapper>
      </IconList>
    </ContactContainer>
  );
}

export default Contact;
