// src/components/CodingProfiles.js
import React from 'react';
import styled from 'styled-components';

const ProfilesContainer = styled.section`
  padding: 20px 0px;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 2rem;
  margin-bottom: 20px;
  color: ${props => props.theme.text};
`;

const ProfileList = styled.div`
  display: flex;
  justify-content: start;
  gap: 20px;
`;

const ProfileLink = styled.a`
  text-decoration: none;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.1);
  }
`;

const Badge = styled.img`
  width: 50px;
  height: 50px;
`;

function CodingProfiles() {
    return (
        <ProfilesContainer id="coding-profiles">
            <Title>Coding Profiles</Title>
            <ProfileList>
                <ProfileLink href="https://www.hackerrank.com/puneethregonda11" target="_blank" rel="noopener noreferrer">
                    <Badge src="https://upload.wikimedia.org/wikipedia/commons/4/40/HackerRank_Icon-1000px.png" alt="HackerRank Badge" />
                </ProfileLink>
                <ProfileLink href="https://leetcode.com/noobsofpearl" target="_blank" rel="noopener noreferrer">
                    <Badge src="https://upload.wikimedia.org/wikipedia/commons/1/19/LeetCode_logo_black.png" alt="LeetCode Badge" />
                </ProfileLink>
            </ProfileList>
        </ProfilesContainer>
    );
}

export default CodingProfiles;