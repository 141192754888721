// src/components/Header.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  z-index: 11;
  padding: 0px 50px;
  width: 100%;
  height: 70px;
  background-color: ${props => props.theme.bg};
  backdrop-filter: blur(10px);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);

  @media (max-width: 768px) {
    padding: 0px 25px;
    height: 60px;
  }
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  color: ${props => props.theme.text};
  font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;
  counter-reset: item 0;
  z-index: 12;
  padding-top: 10px;
`;

const Logo = styled.div`
  font-size: 24px;
  color: ${props => props.theme.highlight};
`;

const NavLinks = styled.ol`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavItem = styled.li`
  margin: 0 5px;
  position: relative;
  counter-increment: item 1;
  font-size: 13px;

  a {
    padding: 10px;
    color: ${props => props.theme.text};
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.highlight};
    }

    &:before {
      content: '0' counter(item) '.';
      margin-right: 5px;
      color: ${props => props.theme.highlight};
      font-size: 12px;
      text-align: right;
    }
  }
`;

const Header = () => {
  const [scrollDirection, setScrollDirection] = useState('none');
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setScrollDirection(currentScrollPos > prevScrollPos ? 'down' : 'up');
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  return (
    <StyledHeader style={{ transform: `translateY(${scrollDirection === 'down' ? '-100%' : '0px'})` }}>
      <Nav>
        <Logo>PR</Logo>
        <NavLinks>
          <NavItem>
            <Link to="about" smooth={true} duration={500}>About</Link>
          </NavItem>
          <NavItem>
            <Link to="experience" smooth={true} duration={500}>Experience</Link>
          </NavItem>
          <NavItem>
            <Link to="projects" smooth={true} duration={500}>Work</Link>
          </NavItem>
          <NavItem>
            <Link to="contact" smooth={true} duration={500}>Contact</Link>
          </NavItem>
        </NavLinks>
      </Nav>
    </StyledHeader>
  );
};

export default Header;