// src/components/Footer.js
import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: ${props => props.theme.bg};
  padding: 40px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  text-align: center;
`;

const FooterText = styled.p`
  font-size: 18px;
  color: ${props => props.theme.text};
  font-weight: bold;
  letter-spacing: 1px;
  
  span {
    color: #FF4500; /* Orange Red for the heart */
    font-size: 20px;
    vertical-align: middle;
    margin: 0 5px;
  }
`;

function Footer() {
  return (
    <FooterContainer>
      <FooterText>
      I <span>❤️</span> Bay Area 
    </FooterText>
    </FooterContainer>
  );
}

export default Footer;