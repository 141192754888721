import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const AnimatedText = ({ words, delay = 50, loop = true }) => {
  const [currentWord, setCurrentWord] = useState(0);
  const [currentLetter, setCurrentLetter] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isDeleting) {
        setCurrentLetter((prevLetter) => prevLetter - 1);
        if (currentLetter === 0) {
          setIsDeleting(false);
          setCurrentWord((prevWord) => (prevWord === 0 ? words.length - 1 : prevWord - 1));
        }
      } else {
        setCurrentLetter((prevLetter) => prevLetter + 1);
        if (currentLetter >= words[currentWord].length) {
          setIsDeleting(true);
        }
      }
    }, delay);

    return () => clearInterval(intervalId);
  }, [words, delay, loop, isDeleting, currentWord, currentLetter]);

  return (
    <span>
      <span className="element" data-values={words.join(',')}>
        {words[currentWord].substring(0, currentLetter + 1)}
      </span>
    </span>
  );
};

const StyledAnimatedText = styled(AnimatedText)`
  .element {
    position: relative;
    display: inline-block;
    :after {
      content: '';
      position: absolute;
      right: 0;
      width: 0;
      height: 100%;
      background-color: ${props => props.theme.highlight};
      transition: width 0.5s;
    }
  }
`;

export default StyledAnimatedText;