// src/components/Experience.js
import React from 'react';
import styled from 'styled-components';
import dbs_logo from '../assets/images/dbs_logo.jpeg'; // Ensure this path is correct


const ExperienceContainer = styled.section`
  padding: 40px 0px; // Added horizontal padding
`;

const Title = styled.h2`
  font-size: 2rem;
  margin-bottom: 20px;
  color: ${props => props.theme.text};
`;

const ExperienceList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ExperienceCard = styled.div`
  background-color: ${props => props.theme.lightBg};
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative; // Allow positioning of the logo
`;

const JobTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

const Company = styled.div`
  display: flex;
  align-items: start;
`;

const CompanyName = styled.p`
  flex: 0 0 20%;
  font-weight: bold;
  margin-right: 10px;
`;

const Logo = styled.img`
  flex: 0 0 10%;
  width: 100px; // Adjust size as needed
  background-color: white;
  margin-bottom: 5px;
`;

const Duration = styled.p`
  font-style: italic;
`;

const Description = styled.ul`
  margin-top: 10px;
`;

const Spacer = styled.div`
  flex: 0 0 67%;
`;

const Experience = () => {
    return (
        <ExperienceContainer id="experience">
            <Title>Where I've Worked</Title>
            <ExperienceList>
                <ExperienceCard>
                    <JobTitle>Software Engineer II</JobTitle>
                    <Company>
                      <CompanyName>DBS Bank</CompanyName>
                      <Spacer/>
                      <Logo src={dbs_logo} alt="DBS Logo" />    
                    </Company>
                    <Duration>Jul 2021 - Aug 2024</Duration>
                    <Description>
                        <li>Won the Star Performer Award for reducing UI development timelines by creating reusable libraries.</li>
                        <li>Developed a payment crediting system handling millions of transactions with sub-500 ms latency.</li>
                        <li>Automated AWS IAM key rotation to enhance resilience and reduce human errors.</li>
                    </Description>
                </ExperienceCard>
            </ExperienceList>
        </ExperienceContainer>
    );
};

export default Experience;