// src/styles/themes.js
import colors from './colorValues';

export const darkTheme = {
  mode: 'dark',  // Added mode property
  bg: colors.dark.bg,
  lightBg: colors.dark.lightBg,
  lightestBg: colors.dark.lightestBg,
  text: colors.dark.text,
  lightText: colors.dark.lightText,
  lightestText: colors.dark.lightestText,
  white: colors.dark.white,
  highlight: colors.dark.highlight,
};

export const lightTheme = {
    mode: 'light',
    bg: colors.light.bg,
    lightBg: colors.light.lightBg,
    lightestBg: colors.light.lightestBg,
    text: colors.light.text,
    lightText: colors.light.lightText,
    lightestText: colors.light.lightestText,
    white: colors.light.white,
    highlight: colors.light.highlight,  // Use the new accent color
    secondary: colors.light.secondary,
    border: colors.light.border,
};