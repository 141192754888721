import React from 'react';
import styled from 'styled-components';
import avatarImage from '../assets/images/me.png'; // Ensure this path is correct

const StyledAbout = styled.section`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 100%;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
`;

const TextContainer = styled.div`
  flex: 0 0 60%;
  padding-right: 2rem; // Use rem for spacing
`;

const Spacer = styled.div`
  flex: 0 0 10%;
`;

const Avatar = styled.img`
  flex: 0 0 150px;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  box-shadow: 0 .25rem .5rem rgba(0,0,0,.1);
  object-fit: cover;

  @media (max-width: 768px) {
    width: 120px;
    height: 120px;
  }
`;

const About = () => {
  return (
    <StyledAbout id="about">
      <TextContainer>
        <h2>About Me</h2>
        <p>
          I'm Puneeth Regonda, a software engineer passionate about crafting exceptional web experiences. With a focus on innovation and design, I transform ideas into reality. Let's connect and create something amazing!
        </p>
      </TextContainer>
      <Spacer />
      <Avatar src={avatarImage} alt="Puneeth Regonda" />
      <Spacer />
    </StyledAbout>
  );
};

export default About;
