// src/styles/GlobalStyle.js
import { createGlobalStyle } from 'styled-components';
import colors from './colorValues';  // Import the color values

const GlobalStyle = createGlobalStyle`
  :root {
    /* Dark theme (current theme) */
    --dark-navy: ${colors.dark.navy};
    --dark-light-navy: ${colors.dark.lightNavy};
    --dark-lightest-navy: ${colors.dark.lightestNavy};
    --dark-slate: ${colors.dark.slate};
    --dark-light-slate: ${colors.dark.lightSlate};
    --dark-lightest-slate: ${colors.dark.lightestSlate};
    --dark-white: ${colors.dark.white};
    --dark-green: ${colors.dark.green};

    /* Light theme (new morning-inspired theme) */
    --light-bg: ${colors.light.bg};
    --light-text: ${colors.light.text};
    --light-highlight: ${colors.light.highlight};
    --light-secondary: ${colors.light.secondary};
    --light-tertiary: ${colors.light.tertiary};
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Calibre', 'Inter', 'San Francisco', 'SF Pro Text', -apple-system, system-ui, sans-serif;
    background-color: ${props => props.theme.bg};
    color: ${props => props.theme.text};
    font-size: 20px;
    line-height: 1.3;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${props => props.theme.headings};
    margin-bottom: 20px;
  }

  section {
    padding: 100px 0;
  }

  @media (max-width: 768px) {
    section {
      padding: 80px 0;
    }
  }

  @media (max-width: 480px) {
    section {
      padding: 60px 0;
    }
  }

  /* Adjust for fixed header */
  main {
    padding-top: 70px;
    @media (max-width: 768px) {
      padding-top: 60px;
    }
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.highlight};
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);

    &:hover,
    &:focus {
      color: ${props => props.theme.highlight};
    }
  }

  button {
    cursor: pointer;
    border: 0;
    border-radius: 0;
  }

  /* Scrollbar Styles */
  html {
    scrollbar-width: thin;
    scrollbar-color: ${props => props.theme.highlight} ${props => props.theme.bg};
  }
  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-track {
    background: ${props => props.theme.bg};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.highlight};
    border: 3px solid ${props => props.theme.bg};
    border-radius: 10px;
  }
`;

export default GlobalStyle;